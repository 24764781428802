import { ActionReducer, Action, INIT, UPDATE } from '@ngrx/store';
import { compare } from 'fast-json-patch';
import { StorageWrapper } from '../abstractions/storage-wrapper';
import { logger } from '../utils/logging';

export const persistantStorageMetaReducerFactory = <S extends object, A extends Action = Action>(
  storageKey: string,
  storageWrapper: StorageWrapper,
) => {
  return (reducer: ActionReducer<S, A>): ActionReducer<S, A> => {
    return (state: S | undefined, action: A): S => {
      // get the next state.
      const nextState = reducer(state, action);

      if ([INIT.toString(), UPDATE.toString()].includes(action.type)) {
        const savedState = storageWrapper.get<S>(storageKey);

        if (savedState) {
          logger(() => {
            const diff = compare(nextState, savedState);
            return ['State differences:', JSON.stringify(diff, undefined, 2)];
          }, 'Local')

          return { ...nextState, ...savedState };
        } else {
          return nextState;
        }
      }

      // save the next state to the application storage.
      storageWrapper.set(storageKey, nextState);

      return nextState;
    };
  };
}
